import React, { useState,useRef,useEffect } from "react";
import { Link,NavLink,useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({slideState ,darkBg=false}) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [changeArrowcolorGd, setChangeArrowColorGd] = useState(false);
  const [changeArrowcolorAbout, setChangeArrowColorAbout] = useState(false);
  const [isDropdownOpenAbout, setIsDropdownOpenAbout] = useState(false);
  const [isDropdownOpenAboutDesk, setIsDropdownOpenAboutDesk] = useState(false);
  const [changeArrowcolorDev, setChangeArrowColorDev] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenGdDesk, setIsDropdownOpenGdDesk] = useState(false);
  const [isDropdownOpenGd, setIsDropdownOpenGd] = useState(false);
  const [isDropdownOpenDevDesk, setIsDropdownOpenDevDesk] = useState(false);
  const [isDropdownOpenDev, setIsDropdownOpenDev] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();
  const dropdownGdRef = useRef();
  const dropdownDevRef = useRef();
  const dropdownAboutRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
      if (dropdownGdRef.current && !dropdownGdRef.current.contains(event.target) && isDropdownOpenGdDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenGdDesk(false);
        }
      }
      if (dropdownDevRef.current && !dropdownDevRef.current.contains(event.target) && isDropdownOpenDevDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDevDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk,isDropdownOpenGdDesk,isDropdownOpenDevDesk]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/services");
        clickCountRef.current = 0;
      }
  };
const onClickGd = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenGd(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenGd(false)
        navigate("/");
        clickCountRef.current = 0;
      }
  };
const onClickAbout = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenAbout(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenAbout(false)
        navigate("/about");
        clickCountRef.current = 0;
      }
  };
const onClickDev = (e) => {
  e.preventDefault();
    
  clickCountRef.current++;
  if (clickCountRef.current === 1) {
    setIsDropdownOpenDev(true);
  } 
  else if (clickCountRef.current === 2) {
    setIsDropdownOpenDev(false)
    clickCountRef.current = 0;
  }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };


  return (
    <>
    
    <div className={`header mt-3 mt-lg-0`} 
      style={darkBg?{backgroundColor:"#292929"}:{}}
    >
      <div className="header-wrapper container">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-lg-0 px-2 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/icons/nav-logo.png" className="nav-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
              <nav 
                // style={{borderLeft:"2px solid #7a7a7a"}}
              >
                <ul
                  className="nav-list d-xl-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/"}  className="nav-links nav-txt">
                      Home
                    </Link>
                  </li>
                 
                  <div className="navigation-dropdown" ref={dropdownAboutRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenAboutDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColorAbout(true)}} onMouseOut={()=>{setChangeArrowColorAbout(false)}}>
                          <Link to={"/about"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenAboutDesk(false)}} style={{color:`${changeArrowcolorAbout ? "#7bba4c":""}`}}>About Us</Link>
                          <img src={`/images/icons/drop-icon.svg`} alt="icon" className={isDropdownOpenAboutDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenAboutDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenAboutDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenAboutDesk(false)}} style={{left:"0"}}>
              
                        <Link to={"/registered-providers"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenAboutDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"capitalize"}}>registered providers</p>
                        </Link>
                     
                      </div>
                    </li>
                    </div>

                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                          <Link to={"/services"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#7bba4c":""}`}}>Services</Link>
                          <img src={`/images/icons/drop-icon.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                      <Link to={"/service/physiotherypy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Physiotherapy</p>
                      </Link>
                      <Link to={"/service/chiropractic-treatment"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Chiropractic Treatment</p>
                      </Link>
                      <Link to={"/service/registered-massage-therapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Registered Massage Therapy</p>
                      </Link>
                      <Link to={"/service/acupuncture"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Acupuncture</p>
                      </Link>
                      <Link to={"/service/psychology"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Psychology</p>
                      </Link>
                      <Link to={"/service/naturopathic-medicine"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Naturopathic Medicine</p>
                      </Link>
                      <Link to={"/service/custom-made-orthotics-and-shoes"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Custom Made Orthotics and Shoes</p>
                      </Link>
                      <Link to={"/service/custom-made-braces"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Custom Made Braces</p>
                      </Link>
                      <Link to={"/service/pelvic-floor-physiotherapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Pelvic Floor Physiotherapy</p>
                      </Link>
                      <Link to={"/service/concussion-rehabilitation"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Concussion Rehabilitation</p>
                      </Link>
                      <Link to={"/service/shockwave-therapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Shockwave Therapy</p>
                      </Link>
                      <Link to={"/service/cold-laser-therapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Cold Laser Therapy</p>
                      </Link>
                      <Link to={"/service/compression-apparel"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                        <p className="mb-0" style={{textTransform:"capitalize"}}>Compression Apparel</p>
                      </Link>

                        
                        
                      </div>
                    </li>
                    </div>

                  {/* <li>
                    <Link to={"/ "} className="nav-links nav-txt">
                    Schedule
                    </Link>
                  </li> */}


                  <div className="navigation-dropdown" ref={dropdownDevRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDevDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColorDev(true)}} onMouseOut={()=>{setChangeArrowColorDev(false)}}>
                          <a  className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDevDesk(false)}} style={{color:`${changeArrowcolorDev ? "#7bba4c":""}`}}>Resources</a>
                          <img src={`/images/icons/drop-icon.svg`} className={isDropdownOpenDevDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDevDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDevDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDevDesk(false)}} style={{left:"0"}}>
              
                        
                        <Link to={"/gallery"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"capitalize"}}>gallery</p>
                        </Link>
                        {/* <Link to={"/form"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"capitalize"}}>forms</p>
                        </Link> */}
                        <Link to={"/blog"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDevDesk(false)}}>
                          <p className="mb-0" style={{textTransform:"capitalize"}}>blogs</p>
                        </Link>
                        
                        
                      </div>
                    </li>
                    </div>
                  <li>
                    <Link to={"/contact"} className="nav-links nav-txt">
                    Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-xl-none d-inline"
              >
                <img
                  src="/images/icons/hamburger-menu.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
                
              </label>
              
              <div className="nav-buttton ml-5 d-xl-flex d-none  extras-btns">
              {/* <div className="search-container" >
                <img className="search-icon" src="/images/icons/nav-search.svg" alt="Search Icon"/>
                <input type="search" className="search-input" placeholder="Search" />
              </div> */}
                <a href='tel:4167447417' className='green-anchor' >
                  <button className="genral-btn" style={{userSelect:"auto"}}>
                  (416)-744-7417
                  </button>
                </a>
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-xl-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-xl-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/"} className="nav-links nav-txt">
                  Home
                </Link>
              </li>
              
                <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickAbout} >
                      <a className="nav-links nav-txt" >  About Us</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpenAbout ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenAbout ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/registered-providers"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenAbout(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"capitalize"}}>registered providers</p>
                    </Link>
                  </div>
                </div>
                </li>
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links nav-txt" >Services</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                  <Link to={"/service/physiotherypy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Physiotherapy</p>
                  </Link>
                  <Link to={"/service/chiropractic-treatment"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Chiropractic Treatment</p>
                  </Link>
                  <Link to={"/service/registered-massage-therapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Registered Massage Therapy</p>
                  </Link>
                  <Link to={"/service/acupuncture"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Acupuncture</p>
                  </Link>
                  <Link to={"/service/psychology"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Psychology</p>
                  </Link>
                  <Link to={"/service/naturopathic-medicine"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Naturopathic Medicine</p>
                  </Link>
                  <Link to={"/service/custom-made-orthotics-and-shoes"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Custom Made Orthotics and Shoes</p>
                  </Link>
                  <Link to={"/service/custom-made-braces"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Custom Made Braces</p>
                  </Link>
                  <Link to={"/service/pelvic-floor-physiotherapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Pelvic Floor Physiotherapy</p>
                  </Link>
                  <Link to={"/service/concussion-rehabilitation"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Concussion Rehabilitation</p>
                  </Link>
                  <Link to={"/service/shockwave-therapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Shockwave Therapy</p>
                  </Link>
                  <Link to={"/service/cold-laser-therapy"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Cold Laser Therapy</p>
                  </Link>
                  <Link to={"/service/compression-apparel"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                    <p className="mb-0" style={{textTransform:"capitalize"}}>Compression Apparel</p>
                  </Link>
                  </div>
                </div>
                </li>
                {/* <li>
                  <Link to={"/ "} className="nav-links nav-txt">
                    Schedule
                  </Link>
                </li> */}
              <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickDev} >
                      <a className="nav-links nav-txt" > Resources</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpenDev ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenDev ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    {/* <Link to={"/registered-providers"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"capitalize"}}>registered providers</p>
                    </Link> */}
                    <Link to={"/gallery"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"capitalize"}}>gallery</p>
                    </Link>
                    {/* <Link to={"/form"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"capitalize"}}>forms</p>
                    </Link> */}
                    <Link to={"/blog"} className="nav-links nav-txt" onClick={()=>{setIsDropdownOpenDev(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"capitalize"}}>blogs</p>
                    </Link>
                    
                    
                  </div>
                </div>
                </li>
              <li>
                <Link to={"/contact"} className="nav-links nav-txt">
                Contact Us
                </Link>
              </li>
            </ul>
            <div className="nav-buttton extras-btns">
            {/* <div className="search-container">
              <img className="search-icon" src="/images/icons/nav-search.svg" alt="Search Icon"/>
              <input type="search" className="search-input" placeholder="Search" />
            </div> */}
                <a href='tel:4167447417' className='green-anchor'>
                  <button className="genral-btn" style={{userSelect:"auto"}}>
                  (416)-744-7417
                  </button>
                </a>

            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
    </>
  );
};

export default Header; 