import React from 'react'
import "./PreHeader.css"

function PreHeader() {
  return (
    <div className='pre-header-container my-0 '>
        <div className="pre-header-green w-100  p-2 ">
        <div className="container d-flex align-items-center justify-content-between px-3">

          {/* <marquee className="body-paragraph" style={{color:"white"}}>
            Book your Virtual care Appointment or in-home Physiotherapy Appointment with True life Wellness and Physiotherapy! Give us a call   (416) - 744 - 7417
            </marquee> */}
          <marquee className="body-paragraph" style={{color:"white"}}>
          Book Your Virtual Care Appointment or In-Home Physiotherapy Appointment with True Life Wellness and Physiotherapy! Give Us a Call (416)-744-7417
            </marquee>
        </div>
      </div>
      <div className="pre-header w-100 d-lg-flex d-none  p-2 ">
        <div className="container d-flex align-items-center px-3">
          <div className="row align-items-center py-3">
            <div className="col-xxl-7 col-12 d-flex align-items-center justify-content-xxl-center justify-content-between">
              <div className="d-flex align-items-center gap-2 px-3">
                <img src="/images/icons/pre-header-loc.svg" />
                <div>
                  <h5 className="nav-title">
                  Address
                  </h5>
                  <a target="blank" href='https://www.google.com/maps/place/True+Life+Wellness+and+Physiotherapy/@43.756613,-79.5697746,17z/data=!3m1!4b1!4m6!3m5!1s0x882b3bb85665cbef:0x966597d65177984d!8m2!3d43.756613!4d-79.5697746!16s%2Fg%2F11f6111ws3?entry=ttu'
                   style={{textDecoration:"none"}}>
                  <p className="upper-nav-txt mb-0">#2-2930 Islington Avenue.  North York, ON.  M9L 2K5</p>
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 px-3">
                <img src="/images/icons/pre-header-time.svg" />
                <div>
                  <h5 className="nav-title">
                  Mon - Fri
                  </h5>
                  <p className="upper-nav-txt mb-0">10:00 AM - 7:00 PM</p>
                </div>
                
              </div>
              <div className="d-flex align-items-center gap-2 px-3">
                <img src="/images/icons/pre-header-phone.svg" />
                <div>
                  <h5 className="nav-title">
                  Contact Number
                  </h5>
                  <a href='tel:(416) - 744 - 7417' style={{textDecoration:"none",userSelect:"auto"}}>
                    <p className="upper-nav-txt mb-0" style={{userSelect:"auto"}}>(416)-744-7417</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-2 col-6 mt-4 mt-xxl-0">
              <p className="upper-nav-txt mb-0">Open Saturdays from 10 AM – 4 PM for all services, in person and virtual.</p>
            </div>
            <div className="col-xxl-3 col-6 mt-4 mt-xxl-0 text-end">
              
              <div className="d-flex align-items-center gap-3 justify-content-end mb-2">
                  <a href="https://www.facebook.com/truelifewellnessandphysio" target="blank"><img className="" alt='icon' src="/images/icons/head-fb.png"/></a>
                  
                  <a href="https://www.instagram.com/truelifewellnessphysio/" target="blank"><img className="" alt='icon' src="/images/icons/head-insta.png"/></a>
                  
                </div>  
            <a href="https://truelifewellnessphysio.janeapp.com/" >
              <button className="genral-btn">
              Book an Appointment
              </button>
            </a>
            </div>
          </div>
          
        </div>
      </div>
      <div className="pre-header w-100 d-lg-none d-flex  mt-4">
        <div className="d-flex justify-content-between px-3 w-100 container">
              
              <div className="d-flex align-items-center gap-3 justify-content-start mb-2">
                  <a href="https://www.facebook.com/truelifewellnessandphysio" target="blank"><img className="" alt='icon' src="/images/icons/head-fb.png"/></a>
                  
                  <a href="https://www.instagram.com/truelifewellnessphysio/" target="blank"><img className="" alt='icon' src="/images/icons/head-insta.png"/></a>
                  
                </div>  
            <a href="https://truelifewellnessphysio.janeapp.com/" >
              <button className="genral-btn">
              Book an Appointment
              </button>
            </a>
            </div>
        </div>
      </div>
  )
}

export default PreHeader