import React, { useEffect, useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TestimonialForm from '../component/TestimonialForm/TestimonialForm'
import KeyFeatureText from '../component/KeyFeatureText/KeyFeatureText'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TextContent from '../component/TextContent/TextContent'
import VideoContent from '../component/VideoContent/VideoContent'


function Physiotherapy() {
  const {id} = useParams()
  const [headingTitle , setHeadingTitle] = useState("")
  const [bannerImg , setBannerImg] = useState("/images/creatives/phys-hero-bg.jpg")
  const [metaTitle , setMetaTitle] = useState("");
  const [metaDesc , setMetaDesc] = useState('');
  
  const navigate = useNavigate()

  const idToInd = () => {
    let servId = 1;
    let bannerHeading= ''
    let bgImg = ""
    let metaT = ""
    let metaD = ""
    switch (id) {
      case "physiotherypy":
        servId = 1;
        bannerHeading = "Physiotherapy";
        bgImg = '/images/creatives/physiotherypy-hero.webp'
        metaT = "Restore Mobility & Wellness | True Life Physiotherapy"
        metaD = "Experience personalized physiotherapy for pain relief & recovery. Our experts offer chiropractic treatment, massage therapy, acupuncture & more. Contact us today!"
        break;
        case "chiropractic-treatment":
          servId = 2;
          bannerHeading = "Chiropractic Treatment";
          bgImg = '/images/creatives/chiropractic-treatment-hero.webp'
          metaT = "Expert Chiropractic Treatment Care for Pain Relief | True Life Wellness"
          metaD = "Experience personalized physiotherapy for pain relief & recovery. Our experts offer chiropractic treatment, massage therapy, acupuncture & more. Contact us today!"
          break;
          case "registered-massage-therapy":
            servId = 3;
            bannerHeading = "Registered Massage Therapy";
            bgImg = '/images/creatives/registered-massage-therapy-hero.webp'
            metaT = "Holistic Massage Therapy Services | True Life Wellness"
            metaD = "Experience relief from muscle tension, pain, and stress with our registered massage therapy. Book your session today for optimal physical and emotional well-being."
        break;
      case "acupuncture":
        servId = 4;
        bannerHeading = "Acupuncture";
        bgImg = '/images/creatives/acupuncture-hero.webp'
        metaT = "Expert Acupuncture Services | True Life Wellness & Physiotherapy"
        metaD = "Discover the healing benefits of acupuncture at True Life Wellness & Physiotherapy. Reduce pain, inflammation, and stress with our licensed acupuncturist."
        break;
      case "psychology":
        servId = 5;
        bannerHeading = "Psychological Counseling";
        bgImg = '/images/creatives/psychology-hero.webp'
        metaT = "Virtual Psychological Counseling Services | True Life Wellness"
        metaD = "Seek confidential mental health support from licensed psychologists at True Life Wellness. Manage stress, anxiety, and depression through secure virtual sessions. "
        break;
      case "naturopathic-medicine":
        servId = 6;
        bannerHeading = "Naturopathic Medicine";
        bgImg = '/images/creatives/naturopathic-medicine-hero.webp'
        metaT = "Holistic Naturopathic Medicine | True Life Wellness"
        metaD = "Experience holistic health with our naturopathic medicine services. Our licensed doctors offer personalized care to optimize nutrition, balance hormones, and enhance vitality. Book now!"
        break;
      case "custom-made-orthotics-and-shoes":
        servId = 7;
        bannerHeading = "Custom Made Orthotics and Shoes";
        bgImg = '/images/creatives/custom-made-orthotics-and-shoes-hero.webp'
        metaT = "Custom Orthotics & Shoes for Pain Relief | True Life Wellness"
        metaD = "Find relief from foot pain with custom-made orthotics and shoes at True Life Wellness. Correct deformities, improve balance, and prevent injuries. Book your appointment today!"
        break;
      case "custom-made-braces":
        servId = 8;
        bannerHeading = "Custom Made Braces";
        bgImg = '/images/creatives/custom-made-braces-hero.webp'
        metaT = "Custom Made Braces for Optimal Support | True Life Wellness"
        metaD = "Regain mobility and prevent further injury with custom-made braces from True Life Wellness. Perfect fit, targeted support, and improved function. Book now!"
        break;
      case "pelvic-floor-physiotherapy":
        servId = 9;
        bannerHeading = "Pelvic Floor Physiotherapy";
        bgImg = '/images/creatives/pelvic-floor-physiotherapy-hero.jpg'
        metaT = "Expert Pelvic Floor Physiotherapy | True Life Wellness & Physiotherapy"
        metaD = "Alleviate pelvic pain and enhance bladder function with specialized pelvic floor physiotherapy at True Life Wellness & Physiotherapy. Book your appointment now!"
        break;
      case "concussion-rehabilitation":
        servId = 10;
        bannerHeading = "Concussion Rehabilitation";
        bgImg = '/images/creatives/concussion-rehabilitation-hero.webp'
        metaT = "Alleviate pelvic pain and enhance bladder function with specialized pelvic floor physiotherapy at True Life Wellness & Physiotherapy. Book your appointment now!"
        metaD = "Recover fully from traumatic brain injuries with our specialized concussion rehabilitation programs. Regain balance, cognitive function, and independence. "
        break;
      case "shockwave-therapy":
        servId = 11;
        bannerHeading = "Shockwave Therapy";
        bgImg = '/images/creatives/shockwave-therapy-hero.webp'
        metaT = "Effective Shockwave Therapy | True Life Wellness & Physiotherapy"
        metaD = "Find relief from chronic pain and musculoskeletal conditions with our non-invasive shockwave therapy. Reduce inflammation, promote tissue regeneration, and accelerate recovery. Book now!"
        break;
      case "cold-laser-therapy":
        servId = 12;
        bannerHeading = "Cold Laser Therapy";
        bgImg = '/images/creatives/cold-laser-therapy-hero.webp'
        metaT = "Advanced Cold Laser Therapy for Pain Relief | True Life Wellness "
        metaD = "Experience effective pain relief and accelerated healing with cold laser therapy at True Life Wellness and Physiotherapy."
        break;
      case "compression-apparel":
        servId = 13;
        bannerHeading = "Compression Apparel";
        bgImg = '/images/creatives/compression-apparel-hero.webp'
        metaT = "Revitalize Your Health with Compression Apparel"
        metaD = "Discover the transformative benefits of compression apparel. Enhance circulation, reduce swelling, and boost muscle recovery. Book now!"
        break;

      default:
        navigate("/services")
        break;
    }

    return {servId:servId , bannerHeading:bannerHeading , bgImg : bgImg , metaT: metaT , metaD : metaD};
  };

  
  
  const [ind,setInd] = useState(idToInd().servId)
  useEffect(()=>{
    setHeadingTitle(()=>idToInd().bannerHeading)
    setInd(()=>idToInd().servId);
    setBannerImg(()=>idToInd().bgImg);
    setMetaTitle(()=>idToInd().metaT)
    setMetaDesc(()=>idToInd().metaD)
  } , [id])
  
    return (
        <div className="home-page">
          <Helmet>
            <title>
            {metaTitle}
            </title>
            <meta
              name="description"
              content={metaDesc}
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading={headingTitle}
            spanHeading=''
            Paragraph=""
            nextLineSpanHeading=""
            bgImg={bannerImg}
            btn={false}
            isCheveron={true}
            btnRef="/"
            txtStart={false}
          />
          
          
    <div className='right-gradient'>
      <div className='left-gradient'>
          <KeyFeatureText
            ind={ind}
          />
          
    </div>

      {
        ind == 9 &&
          <>
            <TextContent
              heading="Prolapse is very common effecting 1 in 3 women who have had children"
              subHeading= ""
              content={"Pelvic floor muscles act as a hammock to support pelvic organs, If either Pelvic floor muscles or the supporting structures are weakened, pelvic organs bulge into the vagina causing vaginal walls to move downwards resulting in prolapse. Obesity, Age and Menopausal changes can also cause prolapse. Freedom+TM autonomic (involuntary) muscular contracts strengthen pelvic floor muscles in subsequently increase vaginal support and prolapse reversal."}
              btn={false}
              btnText="Book An Appointment"
              btnRef='jnape'
              btnIcon={true}
              shiftRow={true}
              marginTop={false}
              img="/images/creatives/pelvic-text-1.png"
              reviewContent={false}
              postBtn={true}
            />
            <TextContent
              heading="How it works"
              subHeading= ""
              content={""}
              customContent={"<strong>Stress Urinary Incontinence (SUI)</strong> Freedom+TM stimulation targets the pelvic and/or pudendal nerves, and the external sphincters. Consequently, restoring PFM muscle integrity.<br/> <strong>In Urge Urinary Incontinence, (UUI)</strong> Freedom+TM targets the afferent branches of the pudendal nerve are stimulated to inhibit the detrusor muscle through central reflexes; at the same time, the efferent nerve branches are also stimulated to facilitate strengthening of the pelvic floor muscles and increase the tonus of the urethral sphincters."}
              btn={false}
              btnText="Book An Appointment"
              btnRef='jnape'
             postBtn={true}
              btnIcon={true}
              shiftRow={false}
              marginTop={false}
              img="/images/creatives/pelvic-text-2.png"
              reviewContent={false}
            />

              <div className='half-gradient-container my-5'>
                <VideoContent
                  img1={"/images/creatives/video-card-pelvic-img.png"}
                  // videoSrc1={"/images/video-content-1.mp4"}
                  singleVideo={true}
                  bottomContent="In Urge Urinary Incontinence, (UUI) Freedom+TM targets the afferent branches of the pudendal nerve are stimulated to inhibit the detrusor muscle through central reflexes; at the same time, the efferent nerve branches are also stimulated to facilitate strengthening of the pelvic floor muscles and increase the tonus of the urethral sphincters."
                  openVideo={false}
                />
              </div>
          </>
      }
    
    </div>
          <TestimonialForm marginBottom={false}/>
    
    
          <Footer/>
          </div>
      )
}

export default Physiotherapy