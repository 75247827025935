import React from 'react'
import "./TeamHelpCall.css"

function TeamHelpCall({
    borderBottom=true
}) {
  return (
    <div className={`TeamHelpCall-container ${borderBottom?"standard-padding-space":"pt-5"}  `}>
        <div className='color-slate-cont'>
        <div className='container pb-5'>
            <div className='row align-items-center row-cont gy-4'>
                <div className='col'>
                    <div className='text-content d-flex flex-column align-items-center align-items-lg-center'>
                        <h3 className='body-heading' style={{color:"#7BBA4C"}}>Need Help? Call Us!</h3>
                        <h3 className='body-heading my-2'>416 - 744 - 7417</h3>
                        {/* <a href='tel:416 - 744 - 7417' className='mt-3'> */}
                        <a href='https://truelifewellnessphysio.janeapp.com/' target='_blank'  rel="noreferrer" className='mt-3'>
                            <button className='genral-btn'>
                                Book An Appointment
                            </button>
                        </a>
                    </div>
                </div>
                {/* <div className='col-lg-6'> 
                    <img className='img-fluid person-img' alt='person-img' src='/images/creatives/doctor-person-img.png'/>
                </div> */}
            </div>
        </div>
        

        </div>
    </div>
  )
}

export default TeamHelpCall