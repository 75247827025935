import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Footer from '../component/Footer/Footer'
import ServicesCarasoul from '../component/ServicesCarasoul/ServicesCarasoul'
import WhyUsBanner from '../component/WhyUsBanner/WhyUsBanner'
import TestimonialForm from '../component/TestimonialForm/TestimonialForm'
import { Helmet } from 'react-helmet'

const cardData = [
  {
    icon:"/images/icons/card-physiotherapy-icon.webp",
    heading:"Physiotherapy",
    content:"Our team of expert physiotherapists are trained in various manual therapies, pelvic floor physiotherapy, and the use of a wide range of modalities  offers a range of modalities including shockwave therapy, cold laser therapy, etc. to address your specific needs and promote healing.",
    anchor:"/service/physiotherapy"
  },
  {
    icon:"/images/icons/card-chiropractic-treatment-icon.png",
    heading:"Chiropractic Treatment",
    content:"Experience hands-on care for spinal, pelvic, and joint disorders, supplemented with therapeutic exercise and lifestyle counseling.",
    anchor:"/service/chiropractic-treatment"
  },
  {
    icon:"/images/icons/card-registered-massage-therapy-icon.webp",
    heading:"Registered Massage Therapy",
    content:"Relax and rejuvenate with either a therapeutic massage or a sports massage tailored to reduce muscle tension and alleviate pain.",
    anchor:"/service/registered-massage-therapy"
  },
  {
    icon:"/images/icons/card-acupuncture-icon.webp",
    heading:"Acupuncture",
    content:"Unlock the benefits of acupuncture for pain relief, stress reduction, and improved energy flow.",
    anchor:"/service/acupuncture"
  },
  {
    icon:"/images/icons/card-psychology-icon.webp",
    heading:"Psychological Counseling (Virtual)",
    content:"Access compassionate support and guidance from our experienced counselors through virtual sessions to address mental health concerns and enhance well-being.",
    anchor:"/service/psychology"
  },
  {
    icon:"/images/icons/card-naturopathic-medicine-icon.webp",
    heading:"Naturopathic Medicine",
    content:"Explore holistic approaches to health and wellness with our naturopathic practitioner, incorporating natural remedies and lifestyle counseling for optimal health.",
    anchor:"/service/naturopathic-medicine"
  },
  {
    icon:"/images/icons/card-custom-made-orthotics-and-shoes-icon.webp",
    heading:"Custom Made Orthotic and Shoes",
    content:"Address misalignments, improve posture, and alleviate pain with either custom-made orthotics, shoes or braces. ",
    anchor:"/service/custom-made-orthotics-and-shoes"
  },
  {
    icon:"/images/icons/card-custom-made-braces-icon.webp",
    heading:"Custom Measured Braces",
    content:"Address complaints such as patellar tendinitis (jumper’s knee), lateral epicondylitis (tennis elbow), etc. with a custom measured brace to reduce the pain during activity. ",
    anchor:"/service/custom-made-braces"
  },
  {
    icon:"/images/icons/card-pelvic-floor-physiotherapy-icon.webp",
    heading:"Pelvic Floor Physiotherapy",
    content:"Specialized treatment for pelvic floor dysfunction, tailored to improve muscle strength, function, and overall pelvic health.",
    anchor:"/service/pelvic-floor-physiotherapy"
  },
  {
    icon:"/images/icons/card-concussion-rehabilitation-icon.webp",
    heading:"Concussion Rehabilitation",
    content:"Comprehensive rehabilitation programs designed to support recovery from concussions, focusing on symptom management and gradual return to daily activities.",
    anchor:"/service/concussion-rehabilitation"
  },
  {
    icon:"/images/icons/card-shockwave-therapy-icon.webp",
    heading:"Shockwave Therapy",
    content:"Effective treatment for various conditions including plantar fasciitis, heel spurs, tendinitis, and trigger points, promoting pain relief and accelerated healing.",
    anchor:"/service/shockwave-therapy"
  },
  {
    icon:"/images/icons/card-cold-laser-therapy-icon.webp",
    heading:"Cold Laser Therapy",
    content:"Non-invasive treatment to reduce pain and inflammation, accelerate tissue repair, and enhance overall recovery.",
    anchor:"/service/cold-laser-therapy"
  },
  {
    icon:"/images/icons/card-compression-apparel-icon.webp",
    heading:"Compression Apparel",
    content:"Explore our selection of specialized compression garments designed to provide support, increase blood circulation, and aid in recovery from various conditions.",
    anchor:"/service/compression-apparel"
  },
  
]

function Home() {
  return (
    <div className="home-page">
      <Helmet>
        <title>
        True Life Wellness & Physiotherapy: Book Your Appointment Today!
        </title>
        <meta
          name="description"
          content="Experience expert care at True Life Wellness & Physiotherapy. Book virtual care or in-home physiotherapy appointments in North York. Call now!"
        ></meta>
      </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="Welcome to "
        spanHeading='True Life Wellness & Physiotherapy in North York'
        nextLineSpanHeading={true}
        Paragraph=""
        btnText="LEARN MORE"
        bgImg="/images/creatives/home-hero-bg.jpg"
        btn={true}
        btnRef="/services"
        txtStart={true}
      />
      
<div className='right-gradient'>
  <div className='left-gradient'>
      
      <TextContent
         heading="Who We Are?"
         subHeading= "About Us"
         content={"True Life Wellness and Physiotherapy, is dedicated to providing comprehensive rehabilitation services to help you achieve your optimal health and wellness. Located conveniently on Islington Avenue between Finch Avenue and Steeles Avenue in North York, Ontario, our clinic offers a range of therapies including Physiotherapy, Pelvic Floor Physiotherapy, Chiropractic Treatment, Registered Massage Therapy, Psychological Counseling, Cold Laser Therapy, Shockwave Therapy, Acupuncture, Naturopathic Medicine, Custom Made Orthotics and Custom Bracing, and Compress Apparel.  With a commitment to personalized care, our team of registered health care practitioners develops customized treatment plans tailored to your specific needs."}
         btn={true}
         btnText="Learn More"
         btnRef='/about'
         btnIcon={true}
         shiftRow={false}
         marginTop={true}
         img="/images/creatives/home-text-1.webp"
         reviewContent={false}
      />
</div>
  {/* <VisionMission/> */}
  <ServicesCarasoul
    heading="Expertise Across Multiple Disciplines"
    subHeading="True Life Wellness & Physiotherapy Services"
    content="Take the first step towards a healthier, happier you by scheduling an appointment at True Life Wellness and Physiotherapy today."
    serviceData={cardData}
  />

</div>
      <WhyUsBanner/>
      <TestimonialForm/>
      <CardCarousel/>


      <Footer/>
      </div>
  )
}

export default Home