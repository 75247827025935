import { useState } from "react";
import "./Footer.css"
import { useNavigate } from "react-router-dom"
import {Spinner} from "react-bootstrap"
import axios from "axios"
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

function Footer() {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-newsletter", {
            email: email,
            formType: "Newsletter",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Newsletter Subscribed Successfully!");
              setLoading(false);
              setEmail("");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Email failed to send, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };

  return (
    <div className="footer-container">

        {/* newsletter Upper section */}
        {/* <div className="upper-newsletter-container container">
            <div className="text-container">
                <div className="newsletter-logo">
                    <img  src="/images/icons/newslater.png" alt="" />
                </div>
                <div className="text-content">
                    <h3>Subscribe Our Newsletter</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
            </div>
            <div className="newsletter-main-form">
                  <form className="newsletter-form" onSubmit={formSubmitHandler}>
                    <input 
                        required
                        type="email"
                        value={email}
                        maxLength="50"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email" 
                    />
                    <button type="submit" disabled={loading}><span className="subscribe-btn-text">Subscribe</span> <img src="/images/icons/Icon-feather-bell.svg" alt="" /></button>
                    </form>
                    {loading ? (
                      <div className="row pb-4">
                        <div className="col-12">
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "white" }}
                          />
                        </div>
                      </div>
                    ) : (
                      success ? (
                        <div>
                          {setLoading(false)}
                          {alert(success)}
                          {setSuccess(false)}
                        </div>
                      ) : (
                        error && (
                          <div>
                            {setLoading(false)}
                            {alert(error)}
                            {setError(false)}
                          </div>
                        )
                      )
                    )}

            </div>
        </div> */}

        {/* div for border lining */}
        {/* <div className="line-seprator container"></div> */}

        {/* middle section */}

        <div className="middle-links-container container">
        <div className="row d-flex align-items-start gy-5 pt-5 w-100">
                <div className="col-lg-4 col-md-6 col-12 mt-md-0 mt-2">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-3">
                            <img  src="/images/icons/tlw-logo-white.png" alt="" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
                        </div>
                        <div className="text-content px-2">
                            <p className="px-2">Reclaim Your Health at True Life Wellness and Physiotherapy.  Every Step Toward Wellness Counts.</p>
                        </div>
                    
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-2">
                    <div className="page-links d-flex flex-xxl-row gap-3 flex-column align-items-xxl-start align-items-center text-center justify-content-md-evenly justify-content-center">
                      <div className="d-flex flex-column flex-md-row gap-3 justify-content-evenly w-100">

                        <div className="text-xxl-start text-center d-flex flex-column gap-3 w-100">
                            <h5 className="main-anchor" >
                              Quick Links
                            </h5>
                            <NavLink to={"/"} className="anchor" exact activeClassName="active">
                                Home
                            </NavLink>
                            <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                            About Us
                            </NavLink>
                            <NavLink to={"/services"} className="anchor" exact activeClassName="active">
                            Services
                            </NavLink>
                            <NavLink to={"/blog"} className="anchor" exact activeClassName="active">
                            Blogs  
                            </NavLink>
                        </div>
                        <div className="text-xxl-start text-center d-flex flex-column gap-3 w-100 mt-xxl-0 mt-3 mt-md-0">
                            <h5 className="main-anchor" >
                              Services
                            </h5>
                            <NavLink to={"/service/physiotherypy"} className="anchor" exact activeClassName="active">
                            Physiotherapy
                            </NavLink>
                            <NavLink to={"/service/chiropractic-treatment"} className="anchor" exact activeClassName="active">
                            Chiropractic Treatment
                            </NavLink>
                            <NavLink to={"/service/acupuncture"} className="anchor" exact activeClassName="active">
                            Acupuncture
                            </NavLink>
                            <NavLink to={"/service/psychology"} className="anchor" exact activeClassName="active">
                            Psychology
                            </NavLink>
                           
                              

                        </div>
                      </div>
                      {/* <div className="d-flex flex-column flex-md-row gap-3 justify-content-evenly w-100">

                        <div className="text-xxl-start text-center d-flex flex-column gap-3 w-100">
                            <h5 className="main-anchor" >
                            Legal
                            </h5>
                            
                           
                            <NavLink to={"/gallery"} className="anchor" exact activeClassName="active">
                            Media
                            </NavLink>

                        </div>
                        <div className="text-xxl-start text-center d-flex flex-column gap-3 w-100">
                            <h5 className="main-anchor" >
                            Support
                            </h5>
                            <NavLink to={"/contact"} className="anchor" exact activeClassName="active">
                            Contact Us   
                            </NavLink>
                            <Link to={"/"} className="anchor" exact activeClassName="active">
                            FAQ
                            </Link>

                        </div>
                      </div> */}
                    </div>
                </div>
                <div className="col-lg-3 col-12 mt-md-0 mt-2">
                    <div className="info-container">
                       <div className="d-flex flex-column align-items-center justify-content-start gap-3">
                            <div className="d-flex align-items-center gap-2 w-100">
                                <img src="/images/icons/phone-icon.svg"/>
                                <div>
                                    <a href="tel:(416) - 744 - 7417" className="mb-0 info-content" style={{color:"white",textDecoration:"none",cursor:"pointer",userSelect:"auto"}}>(416)-744-7417</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 w-100">
                                <img src="/images/icons/mail-icon.svg"/>
                                <div>
                                    <a style={{color:"white",textDecoration:"none",userSelect:"auto"}} target="blank" href="mailto:tlwp@truelifewellnessphysio.ca">
                                        <p className="mb-0 font-mulish info-content">tlwp@truelifewellnessphysio.ca</p>
                                    </a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2  w-100">
                                <img src="/images/icons/location-icon.svg"/>
                                <div>
                                    
                                    <a style={{color:"white",textDecoration:"none"}} target="blank" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x882b3bb85665cbef:0x966597d65177984d?source=g.page.share">
                                        <p className="mb-0 info-content">#2-2930 Islington Avenue.  North York, ON.  M9L 2K5</p>    
                                    </a>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>

            </div>
        </div>

        {/* Lower sections */}

        <div className="lower-container ">
            <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center text-center">
                <small>Made With ❤️ By <a href="https://www.creativesquad.ca/" target="blank">Creative Squad</a></small>
                <small>© Copyright 2025 True Life Wellness & Physiotherapy - All Rights Reserved</small> 
                <div className="d-flex align-items-center gap-2">
                  <a href="https://www.facebook.com/truelifewellnessandphysio" target="blank"><img className="footer-social-icon" src="/images/icons/foot-fb.svg"/></a>
                  {/* <img className="footer-social-icon" src="/images/icons/foot-linked.svg"/> */}
                  <a href="https://www.instagram.com/truelifewellnessphysio/" target="blank"><img className="footer-social-icon" src="/images/icons/foot-insta.svg"/></a>
                  {/* <img className="footer-social-icon" src="/images/icons/foot-twitter.svg"/> */}
                </div>  
            </div>
        </div>
        
    </div>
  )
}

export default Footer