import React,{useState} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner} from "react-bootstrap"
import PreHeader from "../PreHeader/PreHeader";


const HeroBanner = ({
  Heading,
  subHeading,
  postSubHeading=null,
  postSubHeadingRed=null,
  preSpanHeading="",
  spanHeading="",
  Paragraph,
  btnText,
  bgImg,
  bgVideo=false,
  btn=false,
  btnRef="/",
  socialLinks=false,
  serviceForm=false,
  bgEffect=false,
  respBgImg=null,
  isCheveron=false,
  heightAuto=false,
  paraWhite=false,
  bannerMarginBottom=false,
  txtStart=false,
  nextLineSpanHeading=false,
  btnBookAppointment=false
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 796);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 796);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []); 

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Contact page Form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          // setEmail("");
          window.open("/thank-you", '_blank');
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };
  return (
    <>
    <PreHeader/>
    <div
      className={`common_hero_section ${bgVideo && "bg-video"} ${heightAuto && "h-cus-auto"} ${!bannerMarginBottom && "mb-5"}`}
      style={{ backgroundImage: `url(${bgVideo ? bgVideo : bgImg})`,height:`${serviceForm?"auto":"40rem"}`}}
      
    >
      {/* <div className="pre-header-green w-100  p-2 ">
        <div className="container d-flex align-items-center justify-content-between px-3">

          <marquee className="body-paragraph" style={{color:"white"}}>Book your Virtual care Appointment or in-home Physiotherapy Appointment with True life Wellness and Physiotherapy! Give us a call   (416) - 744 - 7417</marquee>
        </div>
      </div>
      <div className="pre-header w-100 d-lg-flex d-none  p-2 ">
        <div className="container d-flex align-items-center px-3">
          <div className="row align-items-center py-3">
            <div className="col-xxl-6 col-12 d-flex align-items-center justify-content-xxl-center justify-content-between">
              <div className="d-flex align-items-center gap-2 px-2">
                <img src="/images/icons/pre-header-loc.svg" />
                <div>
                  <h5 className="nav-title">
                  Address
                  </h5>
                  <p className="upper-nav-txt mb-0">2930 islington Ave #2, North York</p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 px-2">
                <img src="/images/icons/pre-header-time.svg" />
                <div>
                  <h5 className="nav-title">
                  Mon - Fri
                  </h5>
                  <p className="upper-nav-txt mb-0">10:00 AM - 7:00 PM</p>
                </div>
                
              </div>
              <div className="d-flex align-items-center gap-2 px-2">
                <img src="/images/icons/pre-header-phone.svg" />
                <div>
                  <h5 className="nav-title">
                  Contact Number
                  </h5>
                  <p className="upper-nav-txt mb-0">(416) - 744 - 7417</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-6 mt-4 mt-xxl-0">
              <p className="upper-nav-txt mb-0">Open Saturdays from 10 AM - 4 PM for Physio and Massage Open for all services, in person and virtual</p>
            </div>
            <div className="col-xxl-3 col-6 mt-4 mt-xxl-0 text-xxl-center text-end">
              <button className="genral-btn">
              Book an Appointment
              </button>
            </div>
          </div>
          
        </div>
      </div> */}
      <div className={`${bgEffect && "bg-effect"} h-100`}>
       { bgVideo && <video autoPlay loop muted className="bg-video-mp">
        <source src="./images/home_banner_hero.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>} 

      <div className="hero-container h-100  px-0"
      
      >
     <div className="wrapper d-flex flex-column ">
     
      <Header 
      />
      <div 
        className=" d-flex align-items-center py-5 my-auto justify-content-center container"
        >
        <div className="row gy-2 w-100">
          <div
            className={
              serviceForm
                ? "col-lg-6 mt-5"
                : "col-12 hero_content_wrapper px-3 w-100"
            }
          >
            <div className={`hero-text-content ${serviceForm||txtStart?"text-start":"text-center"} ${txtStart && "hero-text-content-limited-width"} ${!txtStart && "mx-auto"}`}>
            <h5 className="hero-sub-heading">{subHeading}</h5>
            <h1 className="hero-section-heading my-2" style={serviceForm ? {fontSize:""}:{}}>
              <span className="heading-color-change">{preSpanHeading}</span>
              {Heading}
              {nextLineSpanHeading && <br></br>}
              <span className="heading-color-change">{spanHeading}</span>
              
            </h1>

           { postSubHeading && <h5 className="hero-sub-heading" >{postSubHeading}</h5>}
           { postSubHeadingRed && <h5 className="sub-heading" >{postSubHeadingRed}</h5>}
            <p className="body-paragraph mx-auto" style={paraWhite?{color:"white"}:{}}>{Paragraph}</p>
            {btn && <button className="genral-btn mt-4" onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
            {btnBookAppointment && 
              <div className="d-flex flex-column align-items-center justify-content-center gap-3 mb-3">
                <button className="genral-btn-light " onClick={()=>{window.open("https://truelifewellnessphysio.janeapp.com/")}}>Book an Appointment</button>
                <button className="genral-btn " onClick={()=>{window.open("tel:(416)-744-7417")}}>Call (416)-744-7417</button>
              </div>
                }
            {isCheveron && <img src="/images/icons/chevron.png" style={{width:"30px",height:"30px"}}/>}
            {socialLinks && (
              <div className="social-links py-2 ">
                <div className="d-flex flex-column flex-lg-row gap-lg-3 gap-0 ">

                  <div className="link-container mt-4 d-flex align-items-center gap-1">
                    <img src="/images/icons/social-phone.svg" />
                    <p className="m-1 body-paragraph" style={{fontSize:"18px"}}>(416)-744-7417</p>
                  </div>
                  {/* <div className="link-container mt-4  d-flex align-items-center gap-1">
                    <img src="/images/icons/social-wa-phone.svg" />
                    <p className="m-1 body-paragraph" style={{fontSize:"18px"}}>(416)-744-7417</p>
                  </div> */}
                </div>
                  <div className="link-container mt-4 d-flex align-items-center gap-1">
                    <img src="/images/icons/social-mail.svg" />
                    <p className="m-1 body-paragraph" style={{fontSize:"18px"}}>tlwp@truelifewellnessphysio.ca</p>
                  </div>
                
                <div className="link-container mt-4 d-flex align-items-center gap-1">
                  <img src="/images/icons/social-location.svg" />
                  <p className="m-1 body-paragraph" style={{fontSize:"18px"}}>#2-2930 Islington Avenue.  North York, ON.  M9L 2K5</p>
                </div>
                {/* <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-time.png" />
                  <p className="m-0 body-paragraph" style={{fontSize:"18px",color:"white"}}>Monday to Friday (7:30 AM - 7:30 PM)</p>
                </div> */}
              </div>
            )}
          </div>
          </div>
          {serviceForm && (
            <div className="col-lg-6 mt-5 pb-5 mx-auto">
              <form className="service-form d-flex flex-column px-5 py-5" onSubmit={formSubmitHandler}>
                <h3 className="body-heading" style={{ color: "#5A5A5A",fontSize:"35px" }}>
                  Let’s get in touch!
                </h3>
                <p
                  className="body-paragraph text-start"
                  style={{ color: "#5A5A5A",fontSize:"20px" }}
                >
                  Your Gateway to Tailored Solutions
                </p>
                <input 
                   type="text" 
                   value={name}
                   maxLength="36"
                   onChange={handleNameChange}
                  placeholder="Your Full Name" 
                />
                <input 
                   type="email" 
                   value={email}
                   maxLength="66"
                   onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />
                <input 
                  type="tel" 
                  value={phone}
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" 
                />
                <input 
                  type="text" 
                  value={message}
                  maxLength="350"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" 
                />
                <button className="service-from-btn  genral-btn px-5" type="submit" disabled={loading} style={{fontWeight:"600"}}>Submit
                {loading ? (
                      <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                      />
                  ) : (
                  success ? (
                      <div>
                      {setLoading(false)}
                      {/* {alert(success)} */}

                      {setSuccess(false)}
                      </div>
                  ) : (
                      error && (
                      <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                      </div>
                      )
                  )
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
      </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default HeroBanner; 