import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GalleryGrid from '../component/GalleryGrid/GalleryGrid'
import { Helmet } from 'react-helmet'

function Gallery() {
    return (
        <div className="home-page">
          <Helmet>
            <title>
            True Life Wellness & Physiotherapy: Your Path to Health
            </title>
            <meta
              name="description"
              content="Experience comprehensive physiotherapy & wellness services in North York. Book now for virtual or in-home appointments. Call (416)-744-7417."
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="Our "
            spanHeading='Gallery'
            Paragraph="Select A Member Below To Learn More About Their Experience And Expertise!"
            nextLineSpanHeading=""
            bgImg="/images/creatives/gallery-hero-bg.jpg"
            btn={false}
            isCheveron={true}
            btnRef="/"
            txtStart={false}
          />
          
    <div className='right-gradient'>
        <GalleryGrid/>
          
    </div>

    <div style={{height:"30px"}}>

    </div>
    
          <Footer/>
          </div>
      )
}

export default Gallery